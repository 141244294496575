<template>
  <v-input
    v-if="visible"
    v-model="value"
    :error-messages="errors"
    type="hidden"
    :hide-details="!errors.length"
    @blur="handleBlur"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { useMatterificField } from '#imports';

export default defineComponent({
  name: 'MtfFieldHidden',
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    visible: { type: Boolean, default: true }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  setup(props, context) {
    const { meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    );
    return {
      meta,
      value,
      errors,
      handleBlur,
      validate,
      hasErrors
    };
  }
});
</script>
